<template>
  <div class="common-page">
    <el-pagination
      class="pagination"
      v-model:current-page="currentPageClone"
      v-model:page-size="pageSizeClone"
      :page-sizes="pageSizes"
      :small="small"
      :layout="layout"
      :total="total"
    />
  </div>
</template>
<script>
export default {
  name: 'commom-page',
  props: {
    'current-page': {
      type: Number,
      default: 1
    },
    'page-size': {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 15, 20]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  emits: ['size-change', 'current-change'],
  data () {
    return {
      // currentPageClone: this.$props['current-page'],
      // pageSizeClone: this.$props['page-size']
    }
  },
  computed: {
    currentPageClone: {
      get () {
        return this.currentPage
      },
      set (val) {
        this.$emit('current-change', val)
      }
    },
    pageSizeClone: {
      get () {
        return this.pageSize
      },
      set (val) {
        this.$emit('size-change', val)
      }
    }
  },
  methods: {
    // handleSizeChange (val) {
    //   this.$emit('size-change', val)
    // },
    // handleCurrentChange (val) {
    //   console.log('分页改变了', val)
    //   this.$emit('current-change', val)
    // }
  }
}
</script>
<style lang="scss" scoped>
.common-page {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  // ::v-deep .pagination {
  //   &.el-pagination.is-background .el-pager li.is-active {
  //     background-color: $colorGreen;
  //     color: white;
  //   }
  //   & .el-pager li:hover {
  //     color: $colorGreen;
  //   }
  //   & .el-pager li.is-active {
  //     color: $colorGreen;
  //   }
  //   & .el-select-dropdown__item.selected {
  //     color: $colorGreen;
  //   }
  // }
}
</style>
